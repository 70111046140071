:root {
    --meridian-blue: #1C3942;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: black;
}

canvas {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: initial;
}

canvas:focus, canvas:active {
    outline: none;
}

label {
    display: block;
    margin-top: 1.25em;
    margin-bottom: 0.25em;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.1em;
    user-select: none;
    color: var(--meridian-blue);
}

input {
    border-radius: 10px;
    padding: 8px 8px 6px 6px;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1em;
    border: 1px solid #D2D2D2;
    background-color: rgba(255, 255, 255,0.5);
    color: var(--meridian-blue);
    outline: none;
}

h2 {
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1em;
    line-height: 1.5em;
    color: var(--meridian-blue);
    display: inline;
}

p {
    display: inline;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.1em;
    float: right;
    color: #122329;
}

.controls {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #11111154;
    background-color: rgba(255, 255, 255,0.85);
    padding: 10px 20px;
    transition: 0.25s;
}

.controls:hover {
    background-color: rgba(255, 255, 255, 1);
}

.controls > * {
    display: inline;
}

.controls > label {
    padding-right: 20px;
}

.controls > input {
    background-color: #1C3942;
}

.left-ctr {
    position: absolute;
    top: 20px;
    left: 20px;
}

.info-ctr {
    position: relative;
    background-color: rgba(255, 255, 255,0.75);
    border-radius: 5px;
    box-shadow: 0px 3px 6px #11111154;
    width: 22.5em;
    transition: 0.25s;
}

.info-ctr:hover {
    background-color: rgba(255, 255, 255, 1);
}

.logo-ctr {
    background-color: var(--meridian-blue);
    padding: 1.1em;
    opacity: 0.95;
    border-radius: 5px 5px 0 0;
}

.logo-ctr>img {
    display: block;
    width: 8.5em;
    margin: 0 auto;
}

.info {
    padding: 1.5em 1.5em;
}

.info>h1 {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    font-style: normal;
    color: var(--meridian-blue);
}

.cal-button {
    margin-top: 2px;
    border-radius: 5px;
    outline: none;
    color: #707070;
    background-color: rgba(255, 255, 255,0.25);
    padding: 8px 15px 5px 15px;
    border: 1px solid #D2D2D2;
    font-size: 1em;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.cal-button:hover, .cal-button:focus {
    color: #1C3942;
    border-color: rgba(28, 57, 66, 0.5);
    cursor: pointer;
}

.full-stats {
    
}

.card {
    position: absolute;
    bottom: 20px;
    left: 20px;
    /* right: 20px; */
    border-radius: 5px;
    box-shadow: 0px 3px 6px #11111154;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 10px 20px;
    transition: 0.25s;
    /* height: 25em; */
    width: 22.5em;

}

.card:hover {
    background-color: rgba(255, 255, 255, 0.95);
}

.card > h2 {
    font-weight: 1000;
    text-align: right;
    display: inline;
    margin: 0 auto;
}

.graph {
    font-family: neue-haas-grotesk-display, sans-serif;
    color: rebeccapurple;
}

